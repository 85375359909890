import * as React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Colors } from '../../consts/colors';
import { MobileFontSizes } from '../../consts/FontSizes';

export interface ISegment {
  value: string | number;
  label: string;
  fullWidth?: boolean;
}

interface ISegmentedControl {
  options?: ISegment[];
  fullWidth?: boolean;
  clearBackground?: boolean;
}

interface ISegmentedControlGroupStyles extends ISegmentedControl {
  mobile: boolean;
  phablet: boolean;
  valueLength?: number;
}

interface ISegmentedControlGroup extends ISegmentedControl, Pick<ISegment, 'value'> {
  updateValue: (a: string | string[] | number | number[]) => void;
}

interface ISegmentedControlGroupStyles extends ISegmentedControl {
  valueLength?: number;
}

const useStyles = makeStyles((theme) => ({
  tbroot: {
    backgroundColor: Colors.white,
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: Colors.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: Colors.white,
      },
    },
    padding: 10,
    marginTop: -10,
  },
  tbselected: {
    '& .MuiToggleButton-label': {
      color: `${Colors.white} !important`,
    },
    '&.MuiToggleButton-root': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  tblabel: {
    color: Colors.black,
    fontSize: (props: ISegmentedControlGroupStyles) =>
      props?.mobile ? MobileFontSizes.xs : MobileFontSizes.sm,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  },
  tggrouped: {
    width: (props: ISegmentedControlGroupStyles) =>
      props.fullWidth && `calc(100%/${props.valueLength})`,
    '&:not(:last-child)': {
      borderTop: `2px solid ${theme.palette.primary.main} !important`,
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
      borderLeft: `none`,
      borderRight: `none`,
    },
    '&:not(:first-child)': {
      borderTop: `2px solid ${theme.palette.primary.main} !important`,
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
      borderRight: `2px solid ${theme.palette.primary.main} !important`,
      borderLeft: `none`,
    },
    '&:last-child': {
      borderTopRightRadius: '15px !important',
      borderBottomRightRadius: '15px !important',
      borderTop: `2px solid ${theme.palette.primary.main} !important`,
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
      borderRight: `2px solid ${theme.palette.primary.main} !important`,
      borderLeft: `none`,
    },
    '&:first-child': {
      borderTopLeftRadius: '15px !important',
      borderBottomLeftRadius: '15px !important',
      border: `2px solid ${theme.palette.primary.main} !important`,
    },
  },
  tggroupedRoot: {
    width: (props: ISegmentedControlGroupStyles) => props.fullWidth && '100%',
    padding: 25,
    background: (props: ISegmentedControlGroupStyles) => props.clearBackground ? 'transparent' : Colors.backgroundDarkGrey,
  },
}));

export const SegmentedControlGroup = (props: ISegmentedControlGroup) => {
  const { value, updateValue, options } = props;
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const phablet = useMediaQuery(useTheme().breakpoints.between('sm', 'md'));
  const styles = useStyles({ ...props, valueLength: options.length, mobile, phablet });

  const handleChange = (e, newValue) => {
    // TODO: Don't want them to select nothing, perhaps this could be override with an `allowEmpty` prop?
    if (!!newValue) {
      updateValue(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      classes={{ grouped: styles.tggrouped, root: styles.tggroupedRoot }}
      exclusive
      value={value}
      onChange={handleChange}
    >
      {options?.map((v, i) => (
        <ToggleButton
          classes={{
            root: styles.tbroot,
            label: styles.tblabel,
            selected: styles.tbselected,
          }}
          key={i}
          value={v.value}
        >
          {v.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

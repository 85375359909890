import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrancherTheme, IBrancherThemePalette } from './BrancherTheme/BrancherTheme';
import { AppMain } from './AppMain';
import { Colors } from './consts/colors';
import { useSelector } from 'react-redux';
import { IStoreTypes } from './store/storeTypes';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.white,
  },
});

const App = () => {
  const styles = useStyles();
  const customPalette = useSelector((state: IStoreTypes) => state.user?.brandPrimaryColor);
  const theme: IBrancherThemePalette = {
    palette: {
      primary: {
        main: customPalette ?? Colors.purple,
      },
      secondary: {
        main: Colors.white,
      },
    }
  }

  return (
    <ThemeProvider theme={createTheme(BrancherTheme(theme) as any)}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={styles.root}>
          <BrowserRouter>
            <AppMain />
          </BrowserRouter>
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;

import { IReducerType } from './ReducerType';
import { PlatformTypes } from '../../types/PlatformTypes';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { IDraggableColumn } from '../../Components/General/DraggableSection';
import { EValues } from '../../Pages/ApplicationForm/Values/ValuesFormConfig';
import { EFormStepperSections } from '../../Pages/ApplicationForm/FormStepper';

export const SAVE_PROGRAM_DATA = 'SAVE_PROGRAM_DATA';
export const SAVE_FORM_CONFIGURATION_DATA = 'SAVE_FORM_CONFIG_DATA';
export const CLEAN_PROGRAM_STATUS_DATA = 'CLEAN_PROGRAM_STATUS_DATA';
export const CLEAN_PROGRAM_DATA = 'CLEAN_PROGRAM_DATA';
export const SAVE_APPLICATION_FORM_DATA = 'SAVE_APPLICATION_FORM_DATA';
export const SAVE_APPLICATION_FORM_DATA_V2 = 'SAVE_APPLICATION_FORM_DATA_V2';
export const SAVE_APPLICATION_DEFAULT_FORM_DATA = 'SAVE_APPLICATION_DEFAULT_FORM_DATA';

export const ProgramReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_PROGRAM_DATA:
      return { ...state, ...action.payload };
    case SAVE_FORM_CONFIGURATION_DATA:
      return { ...state, formConfiguration: { ...state.formConfiguration, ...action.payload } };
    case CLEAN_PROGRAM_STATUS_DATA:
      return { ...state, launchedProgram: false, matchingComplete: false };
    case SAVE_APPLICATION_FORM_DATA_V2:
      const { index, ...newData } = action.payload.data;
      const newFormSectionData = state.applicationForm?.[action.payload?.formSection]?.map((a, i) =>
        i === index ? newData : a,
      );
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          [action.payload?.formSection]: newFormSectionData,
        },
      };
    case SAVE_APPLICATION_FORM_DATA:
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          [action.payload?.formSection]: {
            ...state.applicationForm?.[action.payload?.formSection],
            ...action.payload?.data,
          },
        },
      };
    case SAVE_APPLICATION_DEFAULT_FORM_DATA:
      return { ...state, applicationForm: action.payload };
    case CLEAN_PROGRAM_DATA:
      return initialState;
    default:
      return state;
  }
};

// Support requests
export enum ESupportOptions {
  PLATFORM_ISSUE = 'PLATFORM_ISSUE',
  RELATIONSHIP_ISSUE = 'RELATIONSHIP_ISSUE',
  PARTNER_NOT_RESPONDING = 'PARTNER_NOT_RESPONDING',
  CONFIDENTIAL = 'CONFIDENTIAL',
  LEAVE_PROGRAM = 'LEAVE_PROGRAM',
  OTHER = 'OTHER',
}

export enum ESupportRequestStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface ISupportRequestInfo {
  supportRequestId: string;
  programId: string;
  supportOption: ESupportOptions;
  requestText: string;
  requesterUserId: string;
  status: ESupportRequestStatus;
}

export enum UpdatableFormConfiguration {
  ALGORITHM_CONFIG = 'algorithmConfig',
  FORM_CONFIG = 'formConfig',
  FORM_CONFIG_DEFAULT_VALUES = 'formConfigDefaultValues',
  APPLICATION_CLOSING_DATE = 'applicationClosingDate',
}

export enum UpdatableProgramInfo {
  MATCHING_COMPLETE = 'matchingComplete', // automatic
  LAUNCHED_PROGRAM = 'launchedProgram', // automatic
  ACTIVE = 'active', // automatic
  PROGRAM_NAME = 'programName',
  SUBSCRIPTION = 'subscription',
  USERS = 'userIds',
  SUPPORT_EMAIL = 'supportEmail',
  LAUNCH_DATE = 'launchDate',
  HAS_PLATFORM_ACCESS = 'hasPlatformAccess',
  APPLICATION_ALWAYS_OPEN = 'applicationAlwaysOpen',
  DISABLE_APPLICATION_FORM_NUDGE = 'disableApplicationFormNudge',
  REQUIRES_APPLICATION_APPROVAL = 'requiresApplicationApproval',
  NEXT_NUDGE_DATE = 'nextNudgeDate',
  ON_DEMAND_OPEN = 'onDemandOpen',
  MAXIMUM_MENTOR_AMT = 'maximumMentorAmount',
  MAXIMUM_MENTEE_AMT = 'maximumMenteeAmount',
  NUDGE_LIST = 'nudgeList',
  SURVEYS = 'surveys',
  MAXIMUM_USER_CAP_HARD = 'maximumUserCapHard',
  MAXIMUM_USER_CAP_SOFT = 'maximumUserCapSoft',
  SUBSCRIPTION_END_DATE = 'subscriptionEndDate', // billing - last date of access
  BILLING_DATE = 'billingDate', // billing - next date for billing
  USER_MONTHLY_COST = 'userMonthlyCost', // billing - cost per user per month
  PAID_INSIGHTS = 'paidInsights', // this is a paid addition
  HAS_PERSONALITY_VALUES_INSIGHTS = 'hasPersonalityValuesInsights', // users must have filled out the newer insights form
}

export type TUpdatableProgramSettings =
  | UpdatableProgramInfo.PROGRAM_NAME
  | UpdatableProgramInfo.ACTIVE
  | UpdatableProgramInfo.SUBSCRIPTION
  | UpdatableProgramInfo.SUPPORT_EMAIL
  | UpdatableProgramInfo.HAS_PLATFORM_ACCESS
  | UpdatableProgramInfo.APPLICATION_ALWAYS_OPEN
  | UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL
  | UpdatableProgramInfo.DISABLE_APPLICATION_FORM_NUDGE
  | UpdatableProgramInfo.NEXT_NUDGE_DATE
  | UpdatableProgramInfo.ON_DEMAND_OPEN
  | UpdatableProgramInfo.MAXIMUM_MENTOR_AMT
  | UpdatableProgramInfo.MAXIMUM_MENTEE_AMT
  | UpdatableProgramInfo.MAXIMUM_USER_CAP_HARD
  | UpdatableProgramInfo.MAXIMUM_USER_CAP_SOFT
  | UpdatableProgramInfo.BILLING_DATE // billing
  | UpdatableProgramInfo.USER_MONTHLY_COST // billing
  | UpdatableProgramInfo.SUBSCRIPTION_END_DATE // billing
  | UpdatableProgramInfo.PAID_INSIGHTS
  | UpdatableProgramInfo.HAS_PERSONALITY_VALUES_INSIGHTS
  | UpdatableProgramInfo.NUDGE_LIST;

export interface IUpdatableProgramSettings extends Pick<IProgramInfo, TUpdatableProgramSettings> {}

export interface IAlgorithmType {
  attributeName: string;
  type: EAlgorithmType;
  weight: number;
  readableName?: string;
  mandatory?: boolean;
  secondaryAttributeName?: string;
  tertiaryAttributeName?: string;
  quaternaryAttributeName?: string;
}

export interface IAlgorithmConfig {
  general?: IAlgorithmType[];
  finalising?: IAlgorithmType[];
}

export enum EComponentType {
  TOGGLE = 'toggle',
  TEXT = 'text',
  SELECTION = 'selection',
  SLIDER = 'slider',
  INFORMATION = 'information',
  DND = 'dnd', // Drag and drop
}

export enum EAlgorithmType {
  MORE_THAN_SINGLE = 'moreThanSingle',
  MATCH_MULTIPLE = 'matchMultiple',
  MATCH_SINGLE = 'matchSingle',
  DIFFERENT_SINGLE = 'differentSingle',
  MATCH_EITHER_SIDE = 'matchOneEitherSide',
  MATCH_LOCATION = 'matchLocation',
  MATCH_COUNTRY_STATE_TOWN = 'matchCountryStateTown',
  MATCH_ADVANCED_WORK_AREA = 'matchAdvancedWorkArea',
  MATCH_REGIONAL = 'matchRegional',
}

export interface IValuesPersonalityConfiguration {
  options: Array<{ title: string; name: string }>;
  description: string;
  stage: string;
  question: string;
  version?: 'v1' | 'v2';
  section?: EFormStepperSections;
}

interface IQuestion {
  componentType: EComponentType;
  name: string;
  readableName: string; // For algorithm/reporting
  question: string & { mentee: string; mentor: string };
  description?: string;
  imageLinks?: Array<{ description: string; link: string }>;
  isMentor?: boolean; // Puts this only in the mentor question pile
  isMentee?: boolean; // Redundant for now, but that might change in the future
  algorithmType?: EAlgorithmType; // For algorithm
  notMandatory?: boolean;
  mandatory?: boolean; // For algorithm
  secondaryAttributeName?: IAlgorithmType['secondaryAttributeName']; // For algorithm
  tertiaryAttributeName?: IAlgorithmType['tertiaryAttributeName']; // For algorithm
  quaternaryAttributeName?: IAlgorithmType['quaternaryAttributeName']; // For algorithm
  nextStepName?: string; // For surveys (for now)
}

export interface ISlider extends IQuestion {
  componentType: EComponentType.SLIDER;
  defaultValue?: number;
  max?: number;
  min?: number;
  likertScale?: boolean;
  marks?: Array<{ value: number; label: string }>;
}

export interface IText extends IQuestion {
  componentType: EComponentType.TEXT;
  fullWidth?: boolean; // default is true
  multiline?: boolean; // default is true
  placeholder?: string; // default is "Please enter..."
  label?: string;
  // These 2 were added after the fact
  conditionalTextField?: boolean; // default is true
  textProps?: any;
}

export interface IDragNDrop extends IQuestion {
  componentType: EComponentType.DND;
  columns: IDraggableColumn[];
  vertical?: boolean;
}

export interface IToggle extends IQuestion {
  componentType: EComponentType.TOGGLE;
  options: string[] | string[][];
  exclusive: boolean;
  maximumAmount?: number;
  searchable?: boolean;
  textProps?: Omit<IText, 'componentType' | 'question'>;
  conditionalTextField?: boolean;
  conditionalTextFieldValue?: string;
  // These are for the location based questions - although is for any conditional field really
  // Top level field
  resetField?: string; // lower level field
  resetsOtherField?: boolean;
  // Lower level field
  conditionalOptions?: boolean;
  conditionalOptionsField?: string; // top level field
  conditionalOptionsSecondaryField?: string; // secondary level field
}

interface ISelection extends IQuestion {
  componentType: EComponentType.SELECTION;
}

export type IGeneralQuestionsConfig = IToggle | IText | ISelection | ISlider | IDragNDrop;
export type IFinalisingQuestionsConfig = IToggle | IText | ISelection;

export interface IPersonalityConfigurationV2 {
  options: IValuesPersonalityConfiguration['options'];
  description: string;
  question: string;
  section: EFormStepperSections;
  version?: string; // v2 for new values form
}

export interface IFormQuestionsConfiguration {
  general?: IGeneralQuestionsConfig[];
  values: IValuesPersonalityConfiguration[] & {
    version: string; // v2 for new values form
    question: string;
    description: string;
    items: EValues[][];
  };
  personality: IValuesPersonalityConfiguration[] & IPersonalityConfigurationV2;
  finalising: IFinalisingQuestionsConfig[];
}

export type IDefaultValueType = string | string[] | null;

export interface IDefaultValue {
  value: IDefaultValueType;
  key: string;
}

export enum EApplicationFormSections {
  QUALIFYING = 'qualifying',
  GENERAL = 'general',
  VALUES = 'values',
  PERSONALITY = 'personality',
  FINALISING = 'finalising',
}

export interface IFormConfigDefaultValues {
  qualifying?: {
    tnc?: boolean;
    step?: number;
    position: ProgramPositions | null;
    confirmResponsibility?: boolean;
  };
  general?: {
    mentee: IDefaultValue[];
    mentor: IDefaultValue[];
  };
  values: { key: string; value: any }[] &
    { most: EValues[]; options: EValues[]; least: EValues[] }[];
  personality: any;
  finalising: any;
}

export interface IFormConfig {
  formId?: string;
  formType?: PlatformTypes;
  [UpdatableFormConfiguration.APPLICATION_CLOSING_DATE]?: string;
  [UpdatableFormConfiguration.ALGORITHM_CONFIG]?: IAlgorithmConfig;
  [UpdatableFormConfiguration.FORM_CONFIG]?: IFormQuestionsConfiguration;
  [UpdatableFormConfiguration.FORM_CONFIG_DEFAULT_VALUES]?: IFormConfigDefaultValues;
}

export enum Subscriptions {
  TRAINING = 'training',
  FORM = 'form',
  PROFILE = 'profile',
  GROUPS = 'groups',
  COHORT = 'cohort',
  PRIVATE_NOTES = 'privateNotes',
  SHARED_NOTES = 'sharedNotes',
  MEETINGS = 'meetings',
  MEETINGS_OUTLOOK = 'meetingsOutlook',
  ADHOC_PAIRING = 'adhocPairing',
}

export enum EProgramStatus {
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  ACTIVE = 'ACTIVE',
}

export enum ENudgeList {
  HAS_NO_MENTORING_PARTNER = 'HAS_NO_MENTORING_PARTNER',
  HAS_NOT_COMPLETED_PROFILE = 'HAS_NOT_COMPLETED_PROFILE',
  HAS_NOT_UPLOADED_PROFILE_PIC = 'HAS_NOT_UPLOADED_PROFILE_PIC',
  HAS_NOT_LOGGED_IN_LAST_MONTH = 'HAS_NOT_LOGGED_IN_LAST_MONTH',
  HAS_NOT_COMPLETED_MENTORING_AGREEMENT = 'HAS_NOT_COMPLETED_MENTORING_AGREEMENT',
  HAS_NOT_UPDATED_GOALS = 'HAS_NOT_UPDATED_GOALS',
  HAS_NOT_MET_MENTORING_PARTNER = 'HAS_NOT_MET_MENTORING_PARTNER',
  HAS_NOT_COMPLETED_TRAINING = 'HAS_NOT_COMPLETED_TRAINING',
}

export const subscriptionConfig: { label: string; value: Subscriptions }[] = [
  {
    label: 'Application form',
    value: Subscriptions.FORM,
  },
  {
    label: 'Training',
    value: Subscriptions.TRAINING,
  },
  {
    label: 'Cohort',
    value: Subscriptions.COHORT,
  },
  {
    label: 'Profile',
    value: Subscriptions.PROFILE,
  },
  {
    label: 'Shared Notes',
    value: Subscriptions.SHARED_NOTES,
  },
  {
    label: 'Private Notes',
    value: Subscriptions.PRIVATE_NOTES,
  },
  {
    label: 'Meetings',
    value: Subscriptions.MEETINGS,
  },
  {
    label: 'Outlook Calendar Integration',
    value: Subscriptions.MEETINGS_OUTLOOK,
  },
  {
    label: 'Mentoring on Demand',
    value: Subscriptions.ADHOC_PAIRING,
  },
  {
    label: 'Groups',
    value: Subscriptions.GROUPS,
  },
];

export const programConfig: { label: string; value: PlatformTypes }[] = [
  {
    label: 'Mentoring',
    value: PlatformTypes.mentoring,
  },
  // {
  // 	label: "Peer-mentoring",
  // 	value: PlatformTypes.peer,
  // },
];

export const nudgeListConfig: { label: string; value: ENudgeList }[] = [
  {
    label: 'Has not completed training',
    value: ENudgeList.HAS_NOT_COMPLETED_TRAINING,
  },
  {
    label: 'Has no mentoring partner',
    value: ENudgeList.HAS_NO_MENTORING_PARTNER,
  },
  {
    label: 'Has not completed mentoring agreement',
    value: ENudgeList.HAS_NOT_COMPLETED_MENTORING_AGREEMENT,
  },
  {
    label: 'Has not completed profile',
    value: ENudgeList.HAS_NOT_COMPLETED_PROFILE,
  },
  {
    label: 'Has not uploaded profile picture',
    value: ENudgeList.HAS_NOT_UPLOADED_PROFILE_PIC,
  },
  {
    label: 'Has not logged in last month',
    value: ENudgeList.HAS_NOT_LOGGED_IN_LAST_MONTH,
  },
  {
    label: 'Has not met mentoring partner',
    value: ENudgeList.HAS_NOT_MET_MENTORING_PARTNER,
  },
  {
    label: 'Has not updated goals',
    value: ENudgeList.HAS_NOT_UPDATED_GOALS,
  },
];

interface ISampleApplicationFrom extends IFormConfigDefaultValues {
  position?: ProgramPositions;
  customisedRoleLabels?: string[];
  applicationClosingDate?: string;
}

export interface IProgram {
  programId?: string;
  companyId?: string;
  programLengthMonths?: number;
  formId?: string;
  programType?: PlatformTypes;
  applicationForm?: ISampleApplicationFrom;
  duplicatedProgram?: string;
  onDemandMinimumApplicants?: number;
  roleLabels?: {
    [ProgramPositions.mentee]: string;
    [ProgramPositions.mentor]: string;
    programStyle: string;
  };
  [UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL]?: boolean;
  [UpdatableProgramInfo.ACTIVE]?: boolean;
  [UpdatableProgramInfo.NUDGE_LIST]?: ENudgeList[];
  [UpdatableProgramInfo.ON_DEMAND_OPEN]?: boolean;
  [UpdatableProgramInfo.MAXIMUM_USER_CAP_HARD]?: number;
  [UpdatableProgramInfo.MAXIMUM_USER_CAP_SOFT]?: number;
  [UpdatableProgramInfo.MAXIMUM_MENTOR_AMT]?: number;
  [UpdatableProgramInfo.MAXIMUM_MENTEE_AMT]?: number;
  [UpdatableProgramInfo.NEXT_NUDGE_DATE]?: string; // Date
  [UpdatableProgramInfo.SUPPORT_EMAIL]?: string;
  [UpdatableProgramInfo.BILLING_DATE]?: string;
  [UpdatableProgramInfo.SUBSCRIPTION_END_DATE]?: string;
  [UpdatableProgramInfo.USER_MONTHLY_COST]?: number;
  [UpdatableProgramInfo.APPLICATION_ALWAYS_OPEN]?: boolean;
  [UpdatableProgramInfo.MATCHING_COMPLETE]?: boolean;
  [UpdatableProgramInfo.DISABLE_APPLICATION_FORM_NUDGE]?: boolean;
  [UpdatableProgramInfo.HAS_PLATFORM_ACCESS]?: boolean;
  [UpdatableProgramInfo.LAUNCHED_PROGRAM]?: boolean;
  [UpdatableProgramInfo.PROGRAM_NAME]?: string;
  [UpdatableProgramInfo.SUBSCRIPTION]?: Subscriptions[];
  [UpdatableProgramInfo.USERS]?: string[];
  [UpdatableProgramInfo.LAUNCH_DATE]?: number; // epoch time
  [UpdatableProgramInfo.SURVEYS]?: string[]; // surveyIds
  [UpdatableProgramInfo.PAID_INSIGHTS]?: boolean;
  [UpdatableProgramInfo.HAS_PERSONALITY_VALUES_INSIGHTS]?: boolean;
}

export interface IProgramInfo extends IProgram {
  programIdData?: string;
  menteeAmount?: number;
  mentorAmount?: number;
  submittedMenteeAmount?: number;
  submittedMentorAmount?: number;
  totalUsers?: number;
  totalSubmittedUsers?: number;
  accessibilityRequirements?: number;
  formConfiguration?: IFormConfig;
}

const initialState: IProgramInfo = {};

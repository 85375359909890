import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, SaveUserInfo, UtilGetAllCompanies } from '../../store/actions';
import { IStoreTypes } from '../../store/storeTypes';
import { CreateCompany } from './CreateCompany';
import { Text } from '../../Components/General/Text';
import { Colors } from '../../consts/colors';
import { GetRoute, RouteMaster } from '../../Components/Routing';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { UpdatableCompanyInfo } from '../../store/reducers/CompaniesReducer';
import { GetLinkBase, GetSharedResourceLink } from '../../helpers/LinkHelpers';
import { MasterPairingSatisfactionReport } from '../Reports/MasterPairingSatisfactionReport';
import { ActionOutlinedButton } from '../../Components/InputFields/BrancherButton';

const useStyles = makeStyles((theme) => ({
  margins: {
    marginTop: 60,
  },
  programCard: {
    padding: 15,
    borderRadius: 12,
    width: '100%',
    minWidth: 140,
    border: `2px solid ${Colors.blue}`,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    minHeight: 150,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
    flexDirection: 'column',
  },
  createCompany: {
    marginTop: 30,
    marginBottom: 30,
    padding: 10,
    borderRadius: 12,
    maxWidth: 'fit-content',
    width: 'fit-content',
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    border: `1px solid ${Colors.backgroundGrey}`,
  },
  acceptedFile: {
    maxWidth: 120,
    maxHeight: 120,
    width: 120,
    height: 120,
    borderRadius: 5,
  },
}));

export const SuperUser = () => {
  const [gettingCompanies, setGettingCompanies] = React.useState<boolean>(false);
  const [openCreateCompany, setOpenCreateCompany] = React.useState<boolean>(false);
  const companyList = useSelector((state: IStoreTypes) => state.companies?.companyList);
  const userSelectedCompanyId = useSelector((state: IStoreTypes) => state.user?.selectedCompanyId);
  const isSuperUser = useSelector((state: IStoreTypes) => state.user?.positions).includes(
    AllPositions.BRANCHER_ADMIN,
  );
  const [localCompanyId, setLocalCompanyId] = React.useState<string>();
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    if (isSuperUser) {
      if (userSelectedCompanyId) {
        selectCompany('');
      }
      if (!(companyList?.length > 0)) {
        setGettingCompanies(true);
        BrancherDispatch(
          dispatch,
          UtilGetAllCompanies(() => {
            setGettingCompanies(false);
          }),
        );
      }
    }
  }, []);

  const selectCompany = (selectedCompanyId: string) => {
    const brandPrimaryColor = companyList?.find((c) => c.companyId === selectedCompanyId)?.[UpdatableCompanyInfo.BRAND_PRIMARY_COLOR] ?? Colors.purple;
    dispatch(SaveUserInfo({ selectedCompanyId, brandPrimaryColor }));
    setLocalCompanyId(selectedCompanyId);
  };

  let disabledCompanyList = [];
  let activeCompanyList = [];
  companyList?.length > 0 &&
    companyList?.forEach((c) => {
      if (!!c[UpdatableCompanyInfo.DISABLED]) {
        disabledCompanyList.push(c);
      } else {
        activeCompanyList.push(c);
      }
    });

  const programPlural = (amount: number) => {
    return amount === 1 ? '' : 's';
  };

  return (
    <Page loading={gettingCompanies || !(companyList?.length > 0 || !userSelectedCompanyId)}>
      {!isSuperUser && <Redirect to={RouteMaster.home.path} />}
      {localCompanyId && <Redirect to={RouteMaster.home.path} />}
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={styles.margins}
        spacing={2}
      >
        <Grid container justifyContent="center">
          <Grid container item xs={11}>
            <Grid container spacing={4} item>
              <Grid container item xs={11} alignItems="center" spacing={4}>
                <Grid item>
                  <Text variant="xl" color="purple" display="inline">
                    Active clients
                  </Text>
                </Grid>
                <Grid item container alignItems="center" spacing={2} xs={4}>
                  <ActionOutlinedButton
                    size="small"
                    startIcon={<DashboardOutlined />}
                    onClick={() => window.location.assign(GetRoute('superUserDashboard').path)}
                  >
                    Global Dashboard
                  </ActionOutlinedButton>
                </Grid>
              </Grid>
              {activeCompanyList?.map((company) => (
                <Grid item xs={6} md={4} key={company.companyId} container direction="column">
                  <Box
                    className={styles.programCard}
                    onClick={() => selectCompany(company.companyId)}
                  >
                    <img
                      src={
                        company?.[UpdatableCompanyInfo.WHITE_LABEL]
                          ? `${GetLinkBase()}${company?.[UpdatableCompanyInfo.CUSTOM_LOGO]}`
                          : GetSharedResourceLink('logo.png')
                      }
                      alt={`${company.companyName}-logo`}
                      className={styles.acceptedFile}
                    />
                    <Grid item>
                      <Text variant="md" color="purple" fontWeight={500}>
                        {company.companyName}
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text variant="sm" fontWeight={500}>
                        {company.activeProgramAmount > 0 ? company.activeProgramAmount : 'No'}{' '}
                        active program{programPlural(company.activeProgramAmount)}
                      </Text>
                    </Grid>
                  </Box>
                </Grid>
              ))}

              <Grid container item xs={11}>
                <Grid item>
                  <Text variant="xl" marginBottom={30} marginTop={30} color="purple">
                    Inactive clients
                  </Text>
                </Grid>
              </Grid>
              {disabledCompanyList?.map((company) => (
                <Grid item xs={6} md={4} key={company.companyId} container direction="column">
                  <Box
                    className={styles.programCard}
                    onClick={() => selectCompany(company.companyId)}
                  >
                    <img
                      src={
                        company?.[UpdatableCompanyInfo.WHITE_LABEL]
                          ? `${GetLinkBase()}${company?.[UpdatableCompanyInfo.CUSTOM_LOGO]}`
                          : GetSharedResourceLink('logo.png')
                      }
                      alt={`${company.companyName}-logo`}
                      className={styles.acceptedFile}
                    />
                    <Grid item>
                      <Text variant="md" color="purple" fontWeight={500}>
                        {company.companyName}
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text variant="sm" fontWeight={500}>
                        {company.activeProgramAmount > 0 ? company.activeProgramAmount : 'No'}{' '}
                        active program{programPlural(company.activeProgramAmount)}
                      </Text>
                    </Grid>
                  </Box>
                </Grid>
              ))}
              <Grid container item xs={12}>
                <Grid item xs={6} md={4}>
                  <Box
                    className={styles.programCard}
                    onClick={() => setOpenCreateCompany(!openCreateCompany)}
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      <Add fontSize="large" />
                      <Text variant="md" color="purple" fontWeight={600} marginLeft={20}>
                        Create company
                      </Text>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {openCreateCompany && (
              <Grid item xs={10} sm={8} md={4} className={styles.createCompany}>
                <CreateCompany />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={11}>
          <Grid item>
            <Text variant="xl" marginBottom={20} marginTop={50} color="purple">
              Reports
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <MasterPairingSatisfactionReport />
        </Grid>
      </Grid>
    </Page>
  );
};

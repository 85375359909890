import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDispatch, UtilGetProgramCohortProfiles } from '../../store/actions';
import { Page } from '../../Components/General/Page';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { EProfileAttributes, IProfileData } from '../../types/ProfileTypes';
import { Profile } from './Profile';
import { ProfileCard } from './ProfileCard';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import { EChosenProfile, ReportingProfileOptions } from '../Dashboard/Dashboard';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { GetSharedResourceLink } from '../../helpers/LinkHelpers';
import { BrancherDialog } from '../../Components/General/BrancherDialog';

const useStyles = makeStyles({
  logo: {
    maxHeight: 60,
    maxWidth: 60,
  },
  logoText: {
    position: 'absolute',
  },
});

export const Leaderboard: React.FC = () => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const roleLabels = useSelector((state: IStoreTypes) => state.program.roleLabels);
  const [cohortProfiles, setCohortProfiles] = React.useState<IProfileData[]>([]);
  const [hasCohortData, setHasCohortData] = React.useState<boolean>(false);
  const [cohortNameSearch, setCohortNameSearch] = React.useState<string>('');
  const [profileOpen, setProfileOpen] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<IProfileData | null>();
  const styles = useStyles();

  React.useEffect(() => {
    BrancherDispatch(
      dispatch,
      UtilGetProgramCohortProfiles((cohortData) => {
        if (cohortData.success) {
          setCohortProfiles(
            cohortData.data?.sort((a, b) =>
              b.activityScore ? b.activityScore - a.activityScore : -1,
            ),
          );
        }
        setHasCohortData(true);
      }),
    );
  }, []);

  const controlDialog = (control: boolean) => {
    setProfileOpen(control);
    if (!control) {
      setSelectedUser(null);
    }
  };

  const controlSelectedUser = (user?: IProfileData) => {
    if (!profileOpen) {
      setSelectedUser(user);
      controlDialog(true);
    }
  };

  const SmallLogo = (props: { ranking: number }): React.ReactElement => (
    <>
      <img src={GetSharedResourceLink('logo.png')} alt="brancher-logo" className={styles.logo} />
      <Text variant="sm" display="inline" color="purple" className={styles.logoText}>
        {props.ranking}
      </Text>
    </>
  );

  const profileButtons: React.ReactNode[] = [];
  if (cohortProfiles?.length > 0) {
    const regexp = new RegExp('^' + cohortNameSearch, 'i');
    cohortProfiles
      .filter(
        (profile) =>
          (chosenProfile === EChosenProfile.ALL ||
            (chosenProfile === EChosenProfile.MENTEE &&
              profile.profileType === ProgramPositions.mentee) ||
            (chosenProfile === EChosenProfile.MENTOR &&
              profile.profileType === ProgramPositions.mentor)) &&
          (regexp.test(profile[EProfileAttributes.FIRST_NAME]) ||
            regexp.test(profile[EProfileAttributes.LAST_NAME])),
      )
      ?.forEach((o, i) => {
        profileButtons.push(
          <Badge key={o.roleId} badgeContent={<SmallLogo ranking={i + 1} />} color="primary">
            <ProfileCard
              onSelectUser={() => controlSelectedUser(o)}
              activityScore={o?.activityScore}
              firstName={o?.[EProfileAttributes.FIRST_NAME]}
              lastName={o?.[EProfileAttributes.LAST_NAME]}
              jobTitle={o?.[EProfileAttributes.JOB_TITLE]}
              image={o?.[EProfileAttributes.IMAGE]}
              profileType={o?.profileType}
            />
          </Badge>,
        );
      });
  }

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={11} alignItems="center" spacing={2}>
        <Grid item>
          <Grid item>
            <Text variant="xl" marginTop={30} color="purple">
              {capitalize(roleLabels.programStyle)} leaderboard
            </Text>
          </Grid>
        </Grid>
        <Page loading={!hasCohortData}>
          {selectedUser && (
            <BrancherDialog
              open={profileOpen}
              setClose={() => controlDialog(false)}
              labelledBy={selectedUser[EProfileAttributes.FIRST_NAME]}
              fullScreen={mobile}
            >
              <Box right={0} position="absolute" width="10%" marginTop={2}>
                <CloseIcon
                  onClick={() => controlDialog(false)}
                  color="secondary"
                  fontSize="large"
                />
              </Box>
              <DialogContent>
                <Profile profileData={selectedUser} profileType={selectedUser.profileType} />
              </DialogContent>
            </BrancherDialog>
          )}
          <Grid item xs={11}>
            <SegmentedControlGroup
              value={chosenProfile}
              updateValue={(v: EChosenProfile) => setChosenProfile(v)}
              options={ReportingProfileOptions}
              clearBackground
            />
          </Grid>
          <Grid item xs={12}>
            <Box mb={3} display="flex">
              <BrancherTextField
                value={cohortNameSearch}
                updateValue={setCohortNameSearch}
                fullWidth
                label="Search leaderboard"
              />
            </Box>
          </Grid>
          <Grid item xs={12} container>
            {hasCohortData ? (
              <>
                <Text variant="xs" marginBottom={25} marginTop={-10}>
                  Showing top 20
                </Text>
                <Grid container justifyContent={mobile ? 'center' : 'space-between'} item xs={12}>
                  {profileButtons.slice(0, 20)}
                </Grid>
              </>
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                item
                xs={5}
              >
                <CircularProgress color="secondary" size={64} />
              </Grid>
            )}
          </Grid>
        </Page>
      </Grid>
    </Grid>
  );
};

import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { IDashboardInfo } from '../../store/reducers/DashboardReducer';
import { EChosenProfile, ReportingProfileOptions } from './Dashboard';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';
import { TrainingHealthCard } from './TrainingHealthCard';
import { TrainingHealthOverallCard } from './TrainingHealthOverallCard';
import { TrainingReport } from '../Reports/TrainingReport';

interface ITrainingCompletionHealth {
  trainingCompletions: IDashboardInfo['trainingCompletions'];
  totalTrainingCompletions: IDashboardInfo['totalTrainingCompletions'];
}

export const TrainingCompletionHealth: React.FC<ITrainingCompletionHealth> = (props) => {
  const { trainingCompletions, totalTrainingCompletions } = props;
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const selectedCompletions =
    chosenProfile === EChosenProfile.ALL
      ? trainingCompletions
      : chosenProfile === EChosenProfile.MENTEE
      ? trainingCompletions.filter((a) => a.isMenteeModule)
      : trainingCompletions.filter((a) => a.isMentorModule);

  return (
    <>
      <Grid item xs={12} container alignItems="center" justifyContent="space-between">
        <Grid item>
          <SegmentedControlGroup
            value={chosenProfile}
            updateValue={(v: EChosenProfile) => setChosenProfile(v)}
            options={ReportingProfileOptions}
            clearBackground
          />
        </Grid>
        <Grid item>
          <TrainingReport />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TrainingHealthOverallCard cardTitle="Training stats" {...totalTrainingCompletions} />
      </Grid>
      {selectedCompletions?.map((m, i) => (
        <Grid item xs={12} key={i}>
          <TrainingHealthCard {...m} />
        </Grid>
      ))}
    </>
  );
};

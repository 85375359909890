import figtreeRegular from './fonts/FigTree/Figtree-Regular.ttf';
import figtreeMedium from './fonts/FigTree/Figtree-Medium.ttf';
import figtreeSemiBold from './fonts/FigTree/Figtree-SemiBold.ttf';
import figtreeBold from './fonts/FigTree/Figtree-Bold.ttf';
import figtreeExtraBold from './fonts/FigTree/Figtree-ExtraBold.ttf';

export const figtreeFontRegular = {
  fontFamily: 'Figtree',
  fontOpticalSizing: 'auto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${figtreeRegular}) format('truetype');
  `,
};

export const figtreeFontMedium = {
  fontFamily: 'Figtree',
  fontOpticalSizing: 'auto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${figtreeMedium}) format('truetype');
  `,
};

export const figtreeFontSemiBold = {
  fontFamily: 'Figtree',
  fontOpticalSizing: 'auto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${figtreeSemiBold}) format('truetype');
  `,
};

export const figtreeFontBold = {
  fontFamily: 'Figtree',
  fontOpticalSizing: 'auto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${figtreeBold}) format('truetype');
  `,
};

export const figtreeFontExtraBold = {
  fontFamily: 'Figtree',
  fontOpticalSizing: 'auto',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    url(${figtreeExtraBold}) format('truetype');
  `,
};

export const MuiTypography = {
  fontFamily: ['Figtree', 'sans-serif'].join(','),
};
